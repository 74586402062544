import { inject, Injectable, signal } from "@angular/core";
import { OrderService } from "@data/order/order.service";
import { countAnimation } from "@share-utils/view";
import { Subscription } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TaskAnimationController {
  private static instance: TaskAnimationController;
  coinDisplay$ = signal(0);
  orderService = inject(OrderService);
  // currentUser = inject(UserService).currentUser;

  coinInTopMenuPosition: Position = {
    x: 0, y: 0
  };

  context!: CanvasRenderingContext2D;
  canvas!: HTMLCanvasElement;
  coins: AnimateCoin[] = [];
  startTime: number = 0;
  endSplitTime: number = 0;
  endTime: number = 0;
  coinImage = new Image();
  lastTime = (new Date()).getTime();
  currentTime = 0;
  // you can change animation fps by changing the following variable:
  fps = 30;
  animationInterval = 1000 / this.fps;
  animationFrame = 0;
  startPosition!: Position;
  isAnimating = false;
  splitRadius = 100;
  splitDuration = 300;
  flyToEndDuration = 1000;
  onAnimationEnd = () => { };
  state: CoinAnimationState = 0;
  coinIncreaseSubscription?: Subscription;

  constructor() {
    if (TaskAnimationController.instance) {
      // Already created, return existing instance
      return TaskAnimationController.instance;
    }
    TaskAnimationController.instance = this;
  }

  refreshCoinValue() {
    this.orderService.getCoin().subscribe(coin => {
      this.coinIncreaseSubscription = countAnimation(this.coinDisplay$(), coin, this.flyToEndDuration).subscribe(value => {
        this.coinDisplay$.set(value);
        if (value == coin) {
          this.coinIncreaseSubscription?.unsubscribe();
        }
      })
    });
  }

  clear() {
    this.coinIncreaseSubscription?.unsubscribe();
    this.coinDisplay$.set(0);
  }


  setup({ coinsNumber = 10, minFrame = 0, maxFrame = 5, width = 32, height = 32, callback = () => { } }) {
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.coins = [];
    this.startTime = 0;
    this.isAnimating = false;
    this.animationFrame = 0;
    this.lastTime = (new Date()).getTime();
    this.currentTime = 0;
    this.onAnimationEnd = callback;
    for (let i = 0; i < coinsNumber; i++) {
      const coin = new AnimateCoin(
        this.coinImage, // image
        this.canvas.clientWidth, // canvas width
        this.canvas.clientHeight,// canvas height
        this.coinImage.width,
        this.coinImage.height,
        width, // width
        height, // height
        0, // x
        0, // y
        minFrame, // minFrame
        maxFrame, // maxFrame
        Math.floor(Math.random() * (maxFrame - minFrame + 1) + minFrame), // frame random from minFrame to maxFrame
        this.fps,
        (Math.random() + 1) / 2,
      );
      coin.endPosition = this.coinInTopMenuPosition;
      this.coins.push(coin);
    }

  }

  private animate = () => {

    this.currentTime = (new Date()).getTime();
    if (this.currentTime > this.endTime) {
      this.isAnimating = false;
    }
    if (this.currentTime > this.endSplitTime) {
      if (this.state === CoinAnimationState.Split) this.refreshCoinValue();
      this.state = CoinAnimationState.FlyToEnd;
    }
    if (this.isAnimating) {
      const delta = (this.currentTime - this.lastTime);
      if (delta > this.animationInterval) {
        this.animationFrame++;
        this.context.clearRect(0, 0, this.canvas.clientWidth, this.canvas.clientHeight);
        this.handleCoins();
        this.lastTime = this.currentTime - (delta % this.animationInterval);
      }
    }
    else {
      this.context.clearRect(0, 0, this.canvas.clientWidth, this.canvas.clientHeight);
      this.onAnimationEnd();
      return;
    }
    requestAnimationFrame(this.animate);
  }

  handleCoins() {
    // Update x,y position of each coin from startPosition to coinInTopMenuPosition
    // Update frame of each coin from minFrame to maxFrame
    this.coins.forEach(coin => {
      if (this.state === CoinAnimationState.Split) {
        coin.update(this.state, this.currentTime - this.startTime, this.endSplitTime - this.startTime);
        coin.draw(this.context, this.state, this.currentTime - this.startTime, this.endSplitTime - this.startTime);
      }
      else {
        coin.update(this.state, this.currentTime - this.endSplitTime, this.endTime - this.endSplitTime);
        coin.draw(this.context, this.state, this.currentTime - this.endTime, this.endTime - this.endSplitTime);
      }
    });
  }

  start(startPosition: Position) {
    this.state = CoinAnimationState.Split;
    this.startPosition = startPosition;
    this.coins.forEach((coin, index) => {
      coin.startPosition = startPosition;
      coin.index = index;
      // Split coins from random in circle radius splitRadius with angle index * 2 * Math.PI / coins.length
      coin.splitPosition = {
        x: startPosition.x + Math.cos(index * 2 * Math.PI / this.coins.length) * Math.random() * this.splitRadius,
        y: startPosition.y + Math.sin(index * 2 * Math.PI / this.coins.length) * Math.random() * this.splitRadius
      }
    })
    this.startTime = (new Date()).getTime();
    this.endSplitTime = this.startTime + this.splitDuration;
    this.endTime = this.endSplitTime + this.flyToEndDuration;
    this.lastTime = (new Date()).getTime();
    this.isAnimating = true;
    this.animate();
  }
}

class AnimateCoin {
  image: HTMLImageElement;
  spriteWidth: number;
  spriteHeight: number;
  canvasWidth: number;
  canvasHeight: number;
  coinWidth: number;
  coinHeight: number;
  x: number;
  y: number;
  minFrame: number;
  maxFrame: number;
  frame: number;
  frameX!: number;
  frameY!: number;
  startPosition!: Position;
  splitPosition!: Position;
  endPosition!: Position;
  fps: number;
  scale: number;
  index!: number
  elapsedTime = 0;

  constructor(image: HTMLImageElement, canvasWidth: number, canvasHeight: number, spriteWidth: number, spriteHeight: number, coinWidth: number, coinHeight: number, x: number, y: number, minFrame: number, maxFrame: number, frame: number, fps: number, scale = 1) {
    this.image = image;
    this.spriteWidth = spriteWidth;
    this.spriteHeight = spriteHeight;
    this.canvasWidth = canvasWidth;
    this.canvasHeight = canvasHeight;
    this.coinWidth = coinWidth;
    this.coinHeight = coinHeight;
    this.x = x;
    this.y = y;
    this.minFrame = minFrame;
    this.maxFrame = maxFrame;
    this.frame = frame;
    this.fps = fps;
    this.scale = scale;
  }
  draw(context: CanvasRenderingContext2D, state: number, elapsedTime: number, duration: number) {
    if (state == 0) {
      // Scale from 0 to this.scale with cubitic easing
      const scale = this.scale * (1 - Math.pow(1 - elapsedTime / duration, 3));
      context.drawImage(this.image, this.frameX * this.coinWidth, 0, 32, 32, this.x, this.y, 32, 32);
    } else {
      context.drawImage(this.image, this.frameX * this.coinWidth, 0, 32, 32, this.x, this.y, 32, 32);
    }
    // Draw border of the hold animation area of the canvas
    // if (state === CoinAnimationState.FlyToEnd) {
    //   context.beginPath();
    //   context.strokeStyle = 'red';
    //   context.lineWidth = 1;
    //   context.arc(this.x, this.y, this.coinWidth * this.scale / 2, 0, 2 * Math.PI);
    //   context.stroke();
    // }

  }
  update(state: CoinAnimationState, elapsedTime: number, duration: number) {
    this.updatePosition(state, elapsedTime, duration);
    this.updateFrame();
  }
  private updatePosition(state: CoinAnimationState, elapsedTime: number, duration: number) {
    if (state === CoinAnimationState.Split) {
      this.x = this.startPosition.x + (this.splitPosition.x - this.startPosition.x) * (1 - Math.pow(1 - elapsedTime / duration, 3));
      this.y = this.startPosition.y + (this.splitPosition.y - this.startPosition.y) * (1 - Math.pow(1 - elapsedTime / duration, 3));
    } else {
      // Fly to end using cubic easing in
      this.x = this.splitPosition.x + (this.endPosition.x - this.splitPosition.x) * (1 - Math.cos(elapsedTime / duration * Math.PI)) / 2;
      this.y = this.splitPosition.y + (this.endPosition.y - this.splitPosition.y) * (1 - Math.cos(elapsedTime / duration * Math.PI)) / 2;
      // console.log(this.x, this.y);
    }
  }
  private updateFrame() {
    if (this.frame < this.maxFrame) this.frame++;
    else this.frame = this.minFrame;
    this.frameX = this.frame % (this.spriteWidth / this.coinWidth);
    this.frameY = Math.floor(this.frame / (this.spriteWidth / this.coinWidth));
  }
}

enum CoinAnimationState {
  Split,
  FlyToEnd
}

type Position = {
  x: number;
  y: number;
}

type AnimationFrame = {
  x: number;
  y: number;
  frameX: number;
  frameY: number;
  speedX: number; // Adjust for frame rate
  speedY: number;
}

export { AnimateCoin, AnimationFrame, Position };

