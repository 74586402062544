export function isMobile() {
  var userAgent = window.navigator.userAgent;
  var mobilePatterns = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
  ];

  // Check if any of the mobile patterns match the user agent
  var result = mobilePatterns.some(function (pattern) {
    return pattern.test(userAgent);
  });

  // If it's mobile, return true; if not, return false
  return result;
}
