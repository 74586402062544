import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Router } from '@angular/router';
import { AppPaths } from '@view/routes';
import { Observable, catchError, delay, of, switchMap, throwError, timeout } from 'rxjs';
import { environment } from '../../../environments/environment';
import { NavigationService } from '../navigation/navigation.service';
import { AuthService, TOKEN_KEY } from './auth.service';

export const TOKEN_HEADER_KEY = 'Authorization'; // for Spring Boot back-end
export const serverApi = () => {
  if (window.localStorage.getItem('dev') === 'true') return environment.sandboxApi;
  // if (window.localStorage.getItem('dev') === 'true') return 'http://127.0.0.1:5001/kyonsvn-dev/us-central1/v1';
  return environment.serverApi;
};

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  paths: AppPaths;
  constructor(
    private auth: AuthService,
    private router: Router,
    navService: NavigationService,
    @Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number
  ) {
    this.paths = navService.paths;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = req;
    const token = this.auth.getToken();
    // const contentType = req.headers.get('Content-Type') ?? 'application/json';
    if (token !== '') {
      authReq = req.clone({
        headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token),
      });
    }
    const timeoutValue = req.headers.get('timeout') || this.defaultTimeout;
    const timeoutValueNumeric = Number(timeoutValue);
    return next
      .handle(authReq)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.error instanceof ErrorEvent) {
            console.log('this is client side error');
            return throwError(() => error.error);
          } else {
            if (error.status === 401) {
              if (error.url == `${serverApi()}/auth/refresh`) {
                // this.auth.removeRefreshToken();
                return throwError(() => error.error);
              }
              else {
                // this.auth.removeToken();
              }
              console.log('trying to refresh token');
              return of(null).pipe(
                delay(Math.random() * 500 + 500), // Wait for 1 second
                switchMap(() => {
                  // if (this.auth.isTimedOut()) {
                  return this.handleRefreshToken(authReq, req, next);
                  // }
                  // else {
                  //   return throwError(() => error.error);
                  // }
                }) // Execute myFunction and return its observable
              )
            }
            else if (error.status === 422 && error.url == `${serverApi()}/auth/refresh`) {
              console.log('remove refresh token');
              this.forceSignOut();
              return throwError(() => error.error);
            }
            else if (error.status === 503) {
              // Retry after 1000ms
              return of(null).pipe(
                delay(Math.random() * 500 + 1500), // Wait for 1 second
                switchMap(() => next.handle(authReq)) // Execute myFunction and return its observable
              )
            }
            else {
              console.log('this is server side error');
              return throwError(() => error.error);
            }
          }
        })
      )
      .pipe(timeout(timeoutValueNumeric));
  }

  // handleError<T>(operation = 'operation', result?: T) {
  //   return (error: any): Observable<T> => {
  //     // TODO: send the error to remote logging infrastructure
  //     console.error(error); // log to console instead

  //     // TODO: better job of transforming error for user consumption
  //     console.log(`${operation} failed: ${error.message}`);
  //     // if (error.status === 401) {
  //     //   const refreshToken = this.auth.getRefreshToken();
  //     //   if (refreshToken !== null) {
  //     //     const result = lastValueFrom(this.auth.refreshToken(refreshToken));
  //     //     console.log(result);
  //     //     if (result != null) this.auth.setToken(result);
  //     //     else this.forceSignOut();
  //     //   } else {
  //     //     this.forceSignOut();
  //     //   }
  //     // }
  //     // Let the app keep running by returning an empty result.
  //     return of(result as T);
  //   };
  // }

  forceSignOut() {
    this.auth.signOut({ hasToken: false });
    this.router.navigate([this.paths.signIn.path]);
  }

  private redirectToHome() {
    this.router.navigate([this.paths.home.path]);
  }

  handleRefreshToken(authReq: HttpRequest<any>, req: HttpRequest<any>, next: HttpHandler) {
    const refreshToken = this.auth.getRefreshToken();
    if (refreshToken && refreshToken !== 'undefined') {
      this.auth.removeRefreshToken();
      return this.auth.refreshToken(refreshToken).pipe(
        switchMap((value: any) => {
          if (value[TOKEN_KEY] === undefined || value[TOKEN_KEY] === null || value[TOKEN_KEY] === '') {
            this.forceSignOut();
            return throwError(() => 'Response no access_token');
          }
          this.auth.setToken(value);
          this.auth.setRefreshToken(value);
          authReq = req.clone({
            headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + value.access_token),
          });
          return next.handle(authReq);
        }),
        catchError((e) => {
          console.log(e);
          // this.auth.removeRefreshToken();
          this.forceSignOut();
          return throwError(() => 'Invalid refreshToken');
        })
      );
    } else {
      // this.forceSignOut();
      return throwError(() => 'No refreshToken');
    }
  }
}

export const authInterceptorProviders = [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }];
