<div
  #homeTutorialWrapper
  class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-[100]"
  [ngStyle]="{ height: isOverlap ? 0 : undefined }"
>
  <div #homeTutorial class="tooltip-wrapper absolute" style="top: 50%; left: 50%">
    <div
      #tutorialContainer
      class="tooltip-container p-2 bg-lightBlue-1 rounded-lg text-white col gap-2 w-[225px]"
      [ngClass]="{
        'caret-down': tooltipPosition === 'top',
        'caret-up': tooltipPosition === 'bottom'
      }"
    >
      <span>{{ tooltipContent }}</span>
      <div class="flex items-center justify-between">
        <div class="flex gap-2" *ngIf="scriptElements">
          <button (click)="pre()" [disabled]="step === 0 && back === undefined" class="btn-icon white small link">
            <i class="icon-ChevronLeft"></i>
          </button>
          <button (click)="next()" [disabled]="next === undefined" class="btn-icon white small link">
            <i class="icon-ChevronRight"></i>
          </button>
        </div>
        <button *ngIf="forceComplete === false" (click)="skip()" replaceUrl="true">Bỏ qua</button>
      </div>
    </div>
  </div>
</div>
