import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { extractMath } from 'extract-math';
import katex from 'katex';
import { MarkdownService, ParseOptions } from 'ngx-markdown';

const parseOption: ParseOptions = {
  decodeHtml: false,
  inline: false,
  emoji: false,
  mermaid: false,
  disableSanitizer: true,
};
const mfencedBracesBracketRegex = /<mfenced open="{"(?:.*?)>(.*?)<\/mfenced>/g;

// Replacement function to insert enclosing tags
const replaceMfencedBracesBracketFunction = (match: string, content: string) => {
  return `<mrow><mo>{</mo>${content}<mo>}</mo></mrow>`;
};
const mfencedBracketRegex = /<mfenced>(.*?)<\/mfenced>/g;

// Replacement function to insert enclosing tags
const replaceMfencedBracketFunction = (match: string, content: string) => {
  return `<mrow><mo>(</mo>${content}<mo>)</mo></mrow>`;
};

const replaceReferencesFunction = (match: string, content: string, stringId: string) => {
  const currentUrl = window.location.origin + window.location.pathname;
  return `<a href="${currentUrl}#${stringId}-${content}" >${content}<a>`;
};
// Regex to find references in the [[number]]
const referencesRegex = /\[\[([0-9]+)\]\]/g;

@Component({
  selector: 'kyonsvn-latex',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './latex.component.html',
})
export class LatexComponent implements OnChanges {
  domSanitizer = inject(DomSanitizer);
  markdownService = inject(MarkdownService);

  @Input({ required: true }) inputString!: string;
  @Input() inputStringId?: string;

  _html: string[] = [];
  _safeHtml: SafeHtml | undefined;
  hasLaTex: boolean = false;

  async ngOnChanges(changes: SimpleChanges) {
    this.hasLaTex = false;
    if (changes['inputString']) {
      this._html = [];
      // Break the string into segments ('text', 'inline', and 'display')
      const segments = extractMath(this.inputString.toString(), {
        delimiters: {
          inline: ['$', '$'],
          display: ['$$', '$$']
        }
      })

      // Parse the LaTeX equation to HTML
      for (let i = 0; i < segments.length; i++) {
        if (segments[i]['type'] == 'text') {
          this._html.push(segments[i]['value']);
        }
        else if (segments[i]['type'] == 'inline') {
          const mathString = katex.renderToString(segments[i]['value'], { output: "mathml", throwOnError: false, displayMode: false });
          if (mathString.length != segments[i]['value'].length) {
            this.hasLaTex = true;
          }
          this._html.push(mathString);
        }
        else if (segments[i]['type'] == 'display') {
          const mathString = katex.renderToString(segments[i]['value'], { output: "mathml", throwOnError: false, displayMode: false });
          if (mathString.length != segments[i]['value'].length) {
            this.hasLaTex = true;
          }
          this._html.push(mathString);
        }
        else {
          console.warn("An error occurred when parsing the LaTex input. The type of the segment is not recognized.");
        }
      }

      const markdown = await this.markdownService.parse(this._html.join(''), parseOption);

      // Apply replace using the regular expression and function
      let modifiedMathML = markdown.replace(mfencedBracesBracketRegex, replaceMfencedBracesBracketFunction);
      modifiedMathML = modifiedMathML.replace(mfencedBracketRegex, replaceMfencedBracketFunction);
      if (this.inputStringId !== undefined) { modifiedMathML = modifiedMathML.replace(referencesRegex, (match, content) => replaceReferencesFunction(match, content, this.inputStringId!)); }

      this._safeHtml = this.domSanitizer.bypassSecurityTrustHtml(modifiedMathML);
    }
  }
}
