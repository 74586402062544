import { Subscription } from "rxjs";

function cancel(subscriptions: Subscription[]) {
  subscriptions.forEach(subscription => {
    if (subscription) {
      subscription.unsubscribe();
    }
  })
}

export { cancel };

