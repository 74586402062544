import { Directive } from '@angular/core';

@Directive({
  selector: '[textarea-highlight]',
  standalone: true
})
export class TextareaHighlightDirective {

  constructor() { }

}
