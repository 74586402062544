<div *ngIf="content" class="w-full col">
  <div
    *ngFor="let question of content.questions; let qi = index"
    class="mb-4"
    [ngClass]="{ 'block ': qi === currentIndex, 'hidden ': qi !== currentIndex }"
  >
    <!-- <p [id]="'qtutorial-' + question.id" class="bg-white">
      <kyonsvn-latex [inputString]="question.content | safeHtml"></kyonsvn-latex>
    </p> -->
    <kyonsvn-latex [id]="'qtutorial-' + question.id" [inputString]="question.content"></kyonsvn-latex>
    <div *ngIf="!showResult; else elseBlock" class="mt-2 flex flex-col gap-1">
      <kyonsvn-input-radio
        [style.display]="ai > 3 ? 'none' : 'block'"
        *ngFor="let answer of question.answers; let ai = index"
        (change)="updateProgress(getNextProgress(qi)); updateSubmitData(question.id, answer)"
        [answer]="answer"
        [label]="answerPrefixes[ai] + answer.content"
        [checked]="submission.submitData[question.id] === answer.id"
        [name]="'q' + question.id"
      ></kyonsvn-input-radio>
    </div>
    <ng-template #elseBlock class="">
      <div class="mt-2 flex flex-col gap-1" *ngFor="let answer of question.answers; let ai = index">
        <div
          *ngIf="submission.submitData[question.id] === answer.id || answer.isCorrect"
          class="flex gap-2 p-2 rounded-lg"
          [ngClass]="{
            'bg-red-1 ': submission.submitData[question.id] === answer.id && !answer.isCorrect,
            'bg-emerald-3 ': answer.isCorrect
          }"
        >
          <i
            *ngIf="submission.submitData[question.id] === answer.id && !answer.isCorrect"
            class="icon-Error text-red-0"
          ></i>
          <i *ngIf="answer.isCorrect" class="icon-Check text-darkEmerald"></i>
          <div class="col flex-1">
            <kyonsvn-latex [inputString]="answer.content"></kyonsvn-latex>
            <kyonsvn-latex [inputString]="answer.explanation"></kyonsvn-latex>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
  <kyonsvn-tutorial
    *ngIf="showTutorial"
    (skipCallback)="tutorialScript!.skipCallback()"
    [scriptElements]="scriptElements"
    [scriptEvents]="scriptEvents"
    [back]="backTutorial"
  ></kyonsvn-tutorial>
</div>
<div *ngIf="showGoTo" class="fixed top-0 left-0 w-full h-full flex items-center justify-center z-20">
  <div
    class="absolute top-0 left-0 w-full h-full flex items-center justify-center z-10"
    (click)="showGoTo = false"
  ></div>
  <div class="bg-white rounded-sm z-20">
    <form #f="ngForm">
      <div class="flex items-center gap-2">
        <b>Đến câu:</b
        ><input
          class="w-20"
          type="number"
          [(ngModel)]="goTo"
          #goToElm
          name="goTo"
          [max]="content.questions.length"
          min="1"
        /><button type="submit" class="btn orange" (click)="goToIndex()">Go</button>
      </div>
    </form>
  </div>
</div>
