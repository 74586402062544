<div
  class="w-full flex px-4 py-2 xl:py-2 xl:px-8 items-center justify-between gap-2 relative z-10 border-b border-blueGrey-700"
  [ngClass]="{ showMobileMenu: isShowMobileMenu, isCollapsed, isShowDropdown }"
  (click)="isShowMobileMenu = false"
>
  <div class="md:hidden relative placeholder">
    <div>
      <a class="ai-menu-item text- hidden" [routerLink]="paths.workspace.path" routerLinkActive="active">🚀</a>
      <a class="ai-menu-item text- hidden" [routerLink]="paths.chatbot.path" routerLinkActive="active">🗣️</a>
    </div>
    @if(!isShowDropdown){
    <div class="absolute w-full h-full top-0 left-0" (click)="isShowDropdown = true"></div>
    }
  </div>
  <div class="md:flex p-1 ai-menu-bg rounded-lg md:flex-row flex-col dropdownMenu">
    <div class="fixed top-0 left-0 h-screen w-screen z-10 md:hidden" (click)="isShowDropdown = false"></div>
    <a
      class="ai-menu-item text- z-20"
      [routerLink]="paths.workspace.path"
      routerLinkActive="active"
      (click)="isShowDropdown = false"
      >🚀 Không gian làm việc</a
    >
    <a
      class="ai-menu-item text- z-20"
      [routerLink]="paths.chatbot.path"
      routerLinkActive="active"
      (click)="isShowDropdown = false"
      >🗣️ Chat với Kyo</a
    >
  </div>
  <top-right-menu [backToKyons]="true" />
</div>
