import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NavigationService } from '@data/navigation/navigation.service';
import { TopRightMenuComponent } from '../top-right-menu/top-right-menu.component';

@Component({
  selector: 'ai-menu',
  standalone: true,
  imports: [CommonModule, RouterModule, TopRightMenuComponent],
  templateUrl: './ai-menu.component.html',
  styleUrl: './ai-menu.component.scss',
})
export class AiMenuComponent {
  paths = inject(NavigationService).paths;

  isShowMobileMenu = false;
  isCollapsed = false;
  isShowDropdown = false;

}
