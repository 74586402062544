import { Observable, interval, map, take } from "rxjs";


export function countAnimation(fromValue: number, toValue: number, duration: number): Observable<number> {
  const startTime = Date.now();
  const easeOut = (t: number) => t * (2 - t); // Quadratic ease out function

  return interval(16) // Emit every 16ms (approx. 60fps)
    .pipe(
      map(i => {
        const elapsedTime = Date.now() - startTime;
        const progress = Math.min(elapsedTime / duration, 1);
        const easedProgress = easeOut(progress);
        return Math.round(fromValue + (toValue - fromValue) * easedProgress);
      }),
      take(duration / 16) // Limit to animation duration
    );
}