import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from "@angular/core";
import { TrackingService } from "@data/tracking/tracking.service";

@Component({
  standalone: true,
  imports: [],
  selector: 'student-view-lesson',
  template: `<ng-container></ng-container>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewLessonComponent implements OnInit {
  service = inject(TrackingService);

  @Input() lessonId!: string;
  @Input() trackingType!: string;

  ngOnInit(): void {
    this.service.updateViewOnLesson(this.lessonId, this.trackingType);
  }
}