<ul class="flex gap-2 md:gap-4 item-center">
  <li
    class="flex gap-1 items-center cursor-pointer"
    title="Năng lượng còn lại"
    (click)="showDialog('Mana dùng làm năng lượng cho các hoạt động của AI')"
  >
    <img src="/assets/images/lightning.svg" alt="" class="h-[24px] aspect-square" />
    <strong>{{ mana().value }}</strong>
  </li>
  <li
    class="flex gap-1 items-center cursor-pointer"
    title="Tiền của bạn"
    (click)="showDialog('Koins dùng để mua các vật phẩm trong shop KYONS sẽ ra mắt trong thời gian tới')"
  >
    <img #coinInTopMenuPosition src="/assets/images/Coins.svg" alt="" class="h-[24px] aspect-square" />
    <strong>{{ coin() }}</strong>
  </li>
  <li class="flex item-center">
    <a class="h-[32px] md:h-[48px] aspect-square inline-flex" (click)="profileDialog.showModal()">
      <img src="/assets/images/Avatar.svg" alt="" />
    </a>
  </li>
</ul>
<dialog #dialog (click)="dialog.close()">
  <div class="col w-full items-center gap-6 p-6 pt-10" (click)="$event.stopPropagation()">
    <span>{{ dialogText }}</span>
    <button class="btn orange" (click)="dialog.close()">Đã hiểu</button>
  </div>
</dialog>
<dialog #profileDialog (click)="profileDialog.close()">
  <div id="profileDialog" class="w-[280px] col p-4 gap-4 bg-primaryBlue-500" (click)="$event.stopPropagation()">
    <div id="collapseBtn" class="absolute hidden xl:flex">
      <button class="btn-icon outlined white circle small shadow-1 !bg-primaryBlue-500" (click)="profileDialog.close()">
        <i class="icon-ChevronRight"></i>
      </button>
    </div>
    <div class="flex gap-2 items-center justify-between">
      <h4 class="xl:pl-10 text-orange">Tài khoản</h4>
      <button class="btn-icon link orange xl:hidden" (click)="profileDialog.close()">
        <i class="icon-XClose"></i>
      </button>
    </div>
    <div class="flex gap-2 py-1 items-center">
      <img src="/assets/images/Avatar.svg" alt="" width="40px" height="40px" class="rounded-full" />
      <h6 class="col justify-center">Xin chào, bạn!</h6>
    </div>
    <div class="col gap-2 relative px-4 py-2 bg-secondary-gradient-to-bottom rounded-md">
      <div class="flex justify-between">
        <strong>Dung lượng</strong>
        <span>{{ capacity().sizeDisplay }}/{{ capacity().maxSizeDisplay }}</span>
      </div>
      <div class="w-full h-2 relative bg-blueGrey-300 rounded overflow-hidden">
        <div class="absolute h-full bg-lightBlue-1 rounded left-0 top-0" [style.width]="capacity().percent + '%'"></div>
      </div>
      <a class="btn outlined small rounded-full" [routerLink]="paths.fileManager.path">
        <span></span><i class="icon-Folder"></i><span>Quản lý file</span></a
      >
    </div>
    <hr />
    <ul class="flex flex-col gap-2 w-full font-semibold">
      <li>
        <a
          class="account-item text-white flex justify-between px-4 py-2"
          [routerLink]="paths.account.path"
          [queryParams]="{ tab: 0 }"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="profileDialog.close()"
        >
          <span class="flex items-center gap-2">Tài khoản và thanh toán</span>
          <i class="icon-ChevronRight text-[20px]"></i>
        </a>
      </li>
      <!-- <li
        (click)="activeTab = 1"
        class="account-item"
        [ngClass]="{ 'text-lightBlue-1 bg-lightBlue-6': activeTab === 1 }"
      >
        <span class="flex items-center gap-2"><i class="icon-Profile"></i> Thông tin cá nhân</span>
        <i class="icon-ChevronRight text-[20px]"></i>
      </li> -->
      <li>
        <a
          class="account-item text-white flex justify-between px-4 py-2"
          [routerLink]="paths.account.path"
          [queryParams]="{ tab: 1 }"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="profileDialog.close()"
        >
          <span class="flex items-center gap-2">Thông tin cá nhân</span>
          <i class="icon-ChevronRight"></i>
        </a>
      </li>
      <li>
        <a
          class="account-item text-white flex justify-between px-4 py-2"
          [routerLink]="paths.account.path"
          [queryParams]="{ tab: 2 }"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="profileDialog.close()"
        >
          <span class="flex items-center gap-2">Đổi mật khẩu</span>
          <i class="icon-ChevronRight"></i>
        </a>
      </li>
      <li class="text-orange">
        <a
          class="account-item flex justify-between px-4 py-2"
          [routerLink]="paths.signOut.path"
          (click)="profileDialog.close()"
        >
          <span>Đăng xuất</span>
          <i class="icon-SignOut"></i
        ></a>
      </li>
    </ul>
    <span class="flex-1"></span>
    <!-- <div class="comeback col w-full p-6 xl:py-10 gap-6 xl:gap-8"> -->
    <!-- <h4 class="comeback-title semi hidden xl:flex">Bạn muốn về lại Kyons không?</h4>
      <h5 class="comeback-title semi hidden md:flex xl:hidden">Bạn muốn về lại Kyons không?</h5>
      <h6 class="comeback-title semi md:hidden">Bạn muốn về lại Kyons không?</h6> -->
    <a [routerLink]="paths.home.path" class="btn orange link small"><i class="icon-ChevronLeft"></i> Về lại Kyons</a>
    <!-- </div> -->
  </div>
</dialog>
