<ul
  class="shadow-0 rounded-lg origin-top-right absolute right-0 top-full mt-2 w-56 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none items-stretch flex-col z-10"
>
  <!-- <a [routerLink]="paths.profile.path" class="drop-menu-item">
    <li>Thông tin cá nhân</li>
  </a>
  <li class="border-t border-blueGrey-300"></li> -->
  <li class="drop-menu-item"><a [routerLink]="paths.account.path"> Tài khoản của bạn </a></li>
  <li class="border-t border-blueGrey-300"></li>
  <li class="drop-menu-item">
    <a [routerLink]="paths.home.path" [queryParams]="{ tutorial: true }"> Hướng dẫn sử dụng </a>
  </li>
  <li class="border-t border-blueGrey-300"></li>
  <!-- <li [routerLink]="'/knowledge'">
              <a class="h-5 text-primaryBlue">Cây kiến thức</a>
            </li>
            <li class="border-t border-blueGrey-300"></li> -->
  <li class="drop-menu-item"><a [routerLink]="paths.signOut.path"> Thoát </a></li>
</ul>
