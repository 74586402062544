@if(!isCompact){<student-tracking style="display: contents"></student-tracking>
<div class="col w-full h-full" [ngClass]="{ displaySidebar: showChat() }">
  <div
    class="col h-full"
    [ngClass]="{ 'xl:pl-[80px]': isCollapsed, ' xl:pl-[240px]': !isCollapsed }"
    style="transition: padding 0.2s ease-in-out"
  >
    <!-- <div class="col h-full" style="transition: padding 0.2s ease-in-out"> -->
    <student-menu (collapseEvent)="onCollapse($event)"></student-menu>
    <!-- <ai-menu></ai-menu> -->
    <div class="flex w-full h-[calc(100%_-_64px)]">
      <router-outlet style="display: contents"></router-outlet>
      @if(showChat()&&!isMobile){
      <div class="relative w-[436px] h-full bg-white hidden md:flex flex-col">
        <div class="flex justify-between w-full px-4 pt-2">
          <button (click)="openChatNewTab()" class="btn-icon link">
            <i class="icon-OpenNew" style="transform: rotateY(180deg)"></i>
          </button>
          <button (click)="closeChat()" class="btn-icon link"><i class="icon-XClose"></i></button>
        </div>
        <student-chatbot [isCompact]="true" class="compact" />
      </div>
      }
    </div>
  </div>
</div>
@if(showChat()&&isMobile){
<div class="xl:hidden fixed w-full h-full bg-black bg-opacity-50 flex justify-center p-4 md:p-6 z-[100]">
  <div class="bg-white rounded-md w-full overflow-hidden pt-12 relative">
    <div class="flex justify-between w-full px-2 absolute z-10 top-4">
      <button (click)="openChatNewTab()" class="btn-icon link"><i class="icon-OpenNew"></i></button>
      <button (click)="closeChat()" class="btn-icon link"><i class="icon-XClose"></i></button>
    </div>
    <student-chatbot />
  </div>
</div>
} @if(showKyo&&!showChat()){
<div class="fixed md:right-10 md:bottom-10 right-8 bottom-8">
  <button (click)="openChat()">
    <img src="assets/images/kyons-avatar.svg" alt="" class="w-16 h-16 md:w-20 md:h-20 aspect-square" />
  </button>
</div>
}} @else {
<router-outlet style="display: contents"></router-outlet>
}
