<div class="flex w-full h-full justify-center p-6 md:p-11">
  <div class="flex flex-col w-full max-w-[1122px] gap-6">
    <h2>Chọn nội dung</h2>
    <div class="rounded-lg p-6 flex flex-col gap-6 bg-white items-start" *ngIf="learningGoal">
      <strong
        [ngClass]="{
          'text-red-0':
            isDoneEditing === true &&
            (checkedCount < (learningGoal.minTopic ?? 3) || checkedCount > (learningGoal.maxTopic ?? 4)),
          'text-darkEmerald':
            isDoneEditing &&
            checkedCount >= (learningGoal.minTopic ?? 3) &&
            checkedCount <= (learningGoal.maxTopic ?? 4)
        }"
        >Bạn hãy chọn từ {{ learningGoal.minTopic }} đến {{ learningGoal.maxTopic }} bài để ôn luyện nhé</strong
      >
      <!-- <button class="outlined">
        <span class="flex items-center gap-2"
          ><i class="icon-ChevronLeft"></i> Chọn tất cả <i class="icon-ChevronRight"></i
        ></span>
      </button> -->
      <div
        #turorial1
        class="flex flex-col gap-2 w-full h-[calc(100vh_-_25rem)] overflow-auto"
        data-tooltip-content="Hãy chọn nội dung mà bạn muốn"
      >
        <label *ngFor="let topic of topics" class="border border-blueGrey-300 p-2 rounded checkbox">
          <input class="mr-4" type="checkbox" [(ngModel)]="topic.checked" (change)="updateCount()" />
          <span>{{ topic.name }}</span>
        </label>
      </div>
      <div class="flex-1"></div>
      <div class="self-end">
        <button
          #turorial2
          data-tooltip-content="Bắt đầu làm bài kiểm tra thử thông minh"
          (click)="submit()"
          [disabled]="
            isDoneEditing &&
            (checkedCount < (learningGoal.minTopic ?? 3) || checkedCount > (learningGoal.maxTopic ?? 4))
          "
          class="btn orange"
        >
          Làm bài kiểm tra
        </button>
      </div>
      <kyonsvn-tutorial
        *ngIf="showTutorial"
        (skipCallback)="skip()"
        [scriptElements]="[turorial1, turorial2]"
        [scriptEvents]="[
          ['change', script1],
          ['click', script2]
        ]"
      ></kyonsvn-tutorial>
    </div>
  </div>
  @if(hasError === 'ExistentUnsubmittedMockTest'){
  <div class="fixed w-full h-full left-0 top-0 flex flex-col items-center justify-center bg-blueGrey-100 p-6 md:m-0">
    <div class="w-full md:w-[400px] bg-white rounded-lg p-8 flex flex-col gap-6">
      <div class="flex flex-col gap-2">
        <div class="h7">Chưa hoàn tất bài kiểm tra thử thông minh</div>
        <span>Bạn có một bào kiểm tra thử thông minh chưa hoàn tất, vui lòng vào lộ trình học để tiếp tục làm bài</span>
      </div>
      <div class="w-full">
        <a
          class="btn orange"
          [routerLink]="paths.learningPath.path"
          fragment="mockTestAnchor"
          [queryParams]="{ active_tab: 1 }"
          >Đến lộ trình học</a
        >
      </div>
    </div>
    <div class="h-1/3"></div>
  </div>
  } @else if(hasError!==''){
  <div class="fixed w-full h-full left-0 top-0 flex flex-col items-center justify-center bg-blueGrey-100 p-6 md:m-0">
    <div class="w-full md:w-[400px] bg-white rounded-lg p-8 flex flex-col gap-6">
      <div class="flex flex-col gap-2">
        <div class="h7">Có lỗi</div>
        <span>{{ hasError }}</span>
      </div>
      <div class="w-full">
        <a class="btn orange" (click)="hasError = ''">Đã hiểu</a>
      </div>
    </div>
    <div class="h-1/3"></div>
  </div>
  }
</div>
