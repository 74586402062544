@if(showModels){
<div class="relative">
  <div class="absolute w-full bottom-2 tagging px-4 py-6 gap-4 col z-10">
    @for (model of filteredModels(); track model.hashTag;let i = $index) {
    <button class="w-full flex items-center justify-between gap-8 px-2 cursor-pointer" (click)="selectModel(model)">
      <div class="flex items-center gap-4">
        <img src="{{ model.avatar }}" class="w-[32px] h-[32px]" />
        <span class="text-xs font-bold">{{ model.hashTag }}</span>
      </div>
      <span class="text-xs">{{ model.description }}</span>
    </button>
    }@empty {
    <span>Không tìm thấy</span>
    }
  </div>
</div>
} @if(showSources){
<div class="relative">
  <div class="absolute w-full bottom-2 tagging px-4 py-6 gap-4 col z-10">
    @for (source of filteredSources(); track source.hashTag;let i = $index) {
    <button class="w-full flex items-center gap-8 px-2 cursor-pointer" (click)="selectSource(source)">
      <i class="icon-File01"></i>
      <strong class="line-clamp-1 text-left">{{ source.hashTag }}</strong>
    </button>
    }@empty {
    <span>Không tìm thấy</span>
    }
  </div>
</div>
}
<form class="ask flex gap-2 relative" novalidate>
  <div
    class="flex"
    [ngClass]="{
      'items-center': scrollHeightLevel == 1 && placeholder == null,
      'items-start': scrollHeightLevel > 1 || placeholder != null || captureMathPictureStep > 3
    }"
  >
    <button class="w-[40px] aspect-square left-0 relative" (click)="search = ''; showModels = !showModels">
      @for(model of selectedModels; track model.hashTag;let i = $index){<img
        src="{{ model.avatar }}"
        class="w-[40px] h-[40px] absolute"
        [style.z-index]="selectedModels.length - i"
        [style.right]="i * 8 + 'px'"
      />} @empty {}
    </button>
  </div>
  <div
    class="ask-rounded-border relative flex-1"
    [ngClass]="{
      'flex-row items-center': scrollHeightLevel == 1 && placeholder == null,
      'flex-col': scrollHeightLevel > 1 || placeholder != null || captureMathPictureStep > 3
    }"
    (click)="inputFocus()"
  >
    @if(captureMathPictureStep==1||captureMathPictureStep==2||captureMathPictureStep==3){
    <div class="col w-full gap-2" [ngClass]="{ 'pt-6 px-6': captureMathPictureStep > 1 }">
      <image-cropper
        class="image-cropper"
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="false"
        format="png"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded($event)"
        (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()"
      ></image-cropper>
      @if(captureMathPictureStep>1){
      <div class="flex gap-4 w-full justify-center">
        <button class="btn outlined orange small" [disabled]="captureMathPictureStep == 3" (click)="onFileCancel()">
          Hủy
        </button>
        <button class="btn orange small" [disabled]="captureMathPictureStep == 3" (click)="doneCropping()">Xong</button>
      </div>
      }
    </div>
    } @if(latex.hasLaTex || captureMathPictureStep == 4){
    <div class="w-full relative">
      <div class="flex gap-4 w-full justify-center mb-4">
        <button class="btn orange small" (click)="getMathResult()">Tìm kết quả</button>
        <button class="btn orange small" (click)="getMathWithSolution()">Hướng dẫn giải</button>
      </div>
      @if(captureMathPictureStep == 4){<button
        class="btn-icon link small absolute right-0 top-6"
        (click)="captureMathPictureStep = 0"
      >
        <i class="icon-XClose"></i></button
      >}
    </div>
    <hr />
    } @if(!isGaming){
    <div
      class="flex relative"
      [ngClass]="{
        'basis-0 flex-grow': scrollHeightLevel == 1,
        'basis-auto': scrollHeightLevel >= 2||placeholder != null,
      }"
      [style.height.px]="scrollHeightLevel * 36 + 8"
    >
      <div class="w-full h-full relative">
        <div #backdrop class="askBackdrop absolute w-full h-full top-0 left-0" [ngClass]="{ isThinking }">
          <div class="highlights" [innerHTML]="highlightedText"></div>
        </div>
        <textarea
          class="askInput"
          #askInput
          rows="1"
          placeholder="Trò chuyện..."
          [(ngModel)]="text"
          (ngModelChange)="onValueChange($event)"
          name="text"
          [disabled]="isThinking"
          (keydown.control.enter)="onCtrlEnter()"
          (keydown.enter)="onEnter()"
          (keydown)="onKeydown($event)"
          (resize)="onResize($event)"
          (scroll)="handleScroll()"
          [ngClass]="{
            'basis-0 flex-grow': scrollHeightLevel == 1,
            'basis-auto': scrollHeightLevel >= 2||placeholder != null,
            'z-[-1]': isThinking,
          }"
          [style.height.px]="scrollHeightLevel * 36 + 8"
        ></textarea>
      </div>
      @if(text){
      <button class="absolute btn-icon link small top-1 right-0" (click)="removeText(true)">
        <i class="icon-XClose"></i>
      </button>
      @if(scrollHeightLevel>=3){
      <div class="absolute col bottom-0 right-0">
        <button class="btn-icon link small" (click)="increaseTextareaSize()">
          <i class="icon-ChevronUp"></i>
        </button>
        <button class="btn-icon link small" (click)="decreaseTextareaSize()">
          <i class="icon-ChevronDown"></i>
        </button>
      </div>
      } }
    </div>
    } @if(isGaming) {
    <div class="control">
      <button type="button" class="btn orange outlined" (click)="exit()">Thoát trò chơi</button>
    </div>
    } @if(placeholder){
    <div class="flex px-6 w-full">
      <div class="flex relative w-[120px]">
        @if(placeholder.base64){
        <div
          class="aspect-square w-full bg-contain bg-no-repeat bg-center"
          [style]="{ 'background-image': 'url(' + placeholder.base64 + ')' }"
        ></div>
        } @else{ {{ placeholder.name }} }
        <button
          class="w-[24px] h-[24px] flex items-center justify-center p-1 absolute top-[-10px] right-[-10px]"
          (click)="onDeleteFile()"
        >
          <span class="bg-white w-[20px] h-[20px] border border-gray-300 rounded-xl flex items-center justify-center"
            ><i class="icon-XClose small font-bold"></i
          ></span>
        </button>
      </div>
    </div>
    }
    <hr />
    <div
      class="flex justify-end p-2 gap-2"
      [ngClass]="{
        'w-full': scrollHeightLevel > 1 || placeholder != null || captureMathPictureStep > 1,
        'z-[-1]': isThinking
      }"
    >
      @if(hasSourceFeature){
      <div class="relative">
        @if(showSourceActions){
        <div class="fixed w-full h-full top-0 left-0" (click)="showSourceActions = false"></div>
        <div class="chat-actions absolute w-[381px] bottom-[66px] right-[-70px] z-[1] col p-4 gap-4 rounded-2xl border">
          <div class="w-full grid grid-cols-2 gap-4">
            <a
              class="chat-action p-4 col gap-2.5 justify-start rounded-lg border"
              (click)="text = 'Tạo Summary'; ask()"
            >
              <i class="icon-MessageTextSquare text-emerald-0"></i>
              <span class="font-semibold">Tạo Summary</span>
            </a>
            <a class="chat-action p-4 col gap-2.5 justify-start rounded-lg border" (click)="text = 'Tạo FAQ'; ask()">
              <i class="icon-MessageQuestionCircle text-lightBlue-1"></i>
              <span class="font-semibold">Tạo FAQ</span>
            </a>
            <a
              class="chat-action p-4 col gap-2.5 justify-start rounded-lg border"
              (click)="text = 'Tạo Key-terms'; ask()"
            >
              <i class="icon-MessageSmileSquare text-orange"></i>
              <span class="font-semibold">Tạo Key-terms</span>
            </a>
            <a
              class="chat-action p-4 col gap-2.5 justify-start rounded-lg border"
              (click)="text = 'Tạo Goal Feeder'; ask()"
            >
              <i class="icon-Target04 text-red-0"></i>
              <span class="font-semibold">Tạo Goal Feeder</span>
            </a>
          </div>
          <hr class="border-blueGrey-500" />
          <div class="col gap-3">
            <caption class="text-left">
              Gợi ý
            </caption>
            <div class="col gap-2">
              <button class="p-2 flex items-center gap-2" (click)="getSuggestion()">
                <i class="icon-Lightbulb small text-orange"></i> <span>Cho tôi thêm ý tưởng</span>
              </button>
              <!-- <button class="p-2 flex items-center">
                <i class="icon-Lightbulb small"></i> <span>Cho tôi thêm ý tưởng</span>
              </button> -->
            </div>
          </div>
        </div>
        }
        <button class="btn-icon link" (click)="showSourceActions = !showSourceActions">
          <i class="icon-Star02"></i>
        </button>
      </div>
      <button class="btn-icon link" (click)="onAddFileEvent.emit()"><i class="icon-AddFile"></i></button>
      } @if(hasFileFeature){
      <!-- prettier-ignore-->
      @if(captureMathPictureStep==3){
      <button class="btn-icon link" [ngClass]="{ 'bg-secondaryBlue-500': captureMathPictureStep == 3 }">
        <ng-lottie [options]="options" width="40px" height="40px"></ng-lottie></button
      >}@else {<button
        class="btn-icon link"
        [disabled]="isThinking || captureMathPictureStep > 0"
        (click)="captureMathPicture()"
      >
        <i class="icon-MathCamera"></i>
      </button>
      <!-- -->}
      <button
        class="btn-icon link"
        [matMenuTriggerFor]="fileSelectionMenu"
        [disabled]="isThinking || captureMathPictureStep > 0"
      >
        <i class="icon-AddFile"></i>
      </button>
      <!-- -->
      }
      <button
        class="btn-icon link"
        (click)="ask()"
        [disabled]="isThinking || captureMathPictureStep > 0"
        [ngClass]="{ 'bg-secondaryBlue-500': isThinking }"
      >
        @if(isThinking){<ng-lottie [options]="options" width="40px" height="40px"></ng-lottie>} @else{<i
          class="icon-Send"
        ></i
        >}
      </button>
    </div>
  </div>
</form>
<input
  #file
  hidden
  type="file"
  (change)="onFileSelected($event)"
  [accept]="accept"
  placeholder="Chọn ảnh"
  (cancel)="onFileCancel()"
/>
<mat-menu #fileSelectionMenu xPosition="before" yPosition="above" class="h-[250px] w-[250px]">
  <button mat-menu-item (click)="showFileSelection = true">
    <i class="icon-File"></i>
    <span>Chọn từ quản lý tập tin</span>
  </button>
  <button mat-menu-item (click)="file.value = ''; file.click()">
    <i class="icon-Upload"></i>
    <span>Chọn từ máy tính</span>
  </button>
  <div class="border-t border-dashed px-4 my-2"></div>
  <div class="flex gap-1.5 px-4 text-blueGrey-500">
    <i class="icon-Error small"></i>
    <span>
      Kyo hỗ trợ tải lên:<br />
      <ul class="list-disc">
        <li>Ảnh: kích thước &lt; 10 MB</li>
        <li>Tài liệu: kích thước &lt; 7 MB</li>
      </ul>
      Mỗi lần tải lên một tài liệu duy nhất.
    </span>
  </div>
</mat-menu>
<div
  class="fixed top-0 left-0 w-full h-screen overflow-hidden flex items-center justify-center p-4 bg-blueGrey-100 z-20"
  *ngIf="showFileSelection"
>
  <div class="absolute top-0 left-0 right-0 bottom-0" (click)="showFileSelection = false"></div>
  <div class="w-full max-w-[777px] flex flex-col gap-6 bg-white rounded-lg p-6 relative h-[80%]">
    <file-selection (fileClicked)="onSelectFileFromStorage($event)" [fileService]="fileService" [userId]="userId" />
  </div>
</div>
<dialog #errorDialog (click)="errorDialog.close()">
  <div class="col w-full items-center gap-6 p-6 pt-10" (click)="$event.stopPropagation()">
    <span class="line-clamp-2">{{ error }}</span>
    <button class="btn orange" (click)="errorDialog.close()">Đã hiểu</button>
  </div>
</dialog>
<kyonsvn-latex #latex [inputString]="text" class="hidden" />
