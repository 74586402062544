import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChatController {
  private static instance: ChatController;
  constructor() {
    if (ChatController.instance) {
      // Already created, return existing instance
      return ChatController.instance;
    }
    ChatController.instance = this;
  }

  showChat = signal(false);

  refresh() {
    // this.service.getMana(this.userId);
  }

  toggleKyo(value: boolean) {
    this.showChat.set(value);
  }
}