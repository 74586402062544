import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UserService } from '@data/user/user.service';
import { LangKey, Workspace } from '@data/workspace/workspace-models';
import { WorkspaceService } from '@data/workspace/workspace.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'workspace-edit',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './workspace-edit.component.html',
  styleUrl: './workspace-edit.component.scss'
})
export class WorkspaceEditComponent implements OnChanges {
  service = inject(WorkspaceService);
  userId = inject(UserService).currentUser().uuid;

  name = '';
  goal = '';
  processing = false;
  LangKey = LangKey;
  language = LangKey.VI;
  error = '';

  @Input({ required: true }) workspace!: Workspace;
  @Output() closeOnEditEvent = new EventEmitter<any>();
  @Output() closeOnDeleteEvent = new EventEmitter<boolean>();

  @ViewChild('errorDialog') errorDialogElm!: ElementRef<HTMLDialogElement>;
  @ViewChild('deleteDialog') deleteDialogElm!: ElementRef<HTMLDialogElement>;

  edit() {
    this.processing = true;
    firstValueFrom(this.service.editWorkspace(this.userId, this.workspace.id, { name: this.name, goal: this.goal, language: this.language })).then(
      (data) => {
        this.processing = false;
        this.closeOnEditEvent.emit(data);
      },
      (err) => {
        console.error(err);
        this.error = JSON.stringify(err);
        this.errorDialogElm.nativeElement.showModal();
      }
    );
  }

  delete() {
    firstValueFrom(this.service.deleteWorkspace(this.userId, this.workspace.id)).then(
      () => {
        this.deleteDialogElm.nativeElement.close();
        this.closeOnDeleteEvent.emit(true);
      },
      (err) => {
        console.error(err);
        this.error = JSON.stringify(err);
        this.errorDialogElm.nativeElement.showModal();
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['workspace'].previousValue && changes['workspace'].currentValue.id !== changes['workspace'].previousValue.id) {
      this.name = this.workspace.name;
      this.goal = this.workspace.goal;
      this.language = this.workspace.language;
      this.error = '';
    }
  }

}
