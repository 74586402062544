import { CommonModule } from '@angular/common';
import { Component, HostBinding, OnInit, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { UserService } from '@data/user/user.service';
import { notHaveDigit, notHaveSpecial, notHaveUppercase, search } from '@share-utils/utils';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'student-change-password',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './change-password.component.html',
})
export class ChangePasswordComponent implements OnInit {
  fb = inject(FormBuilder);
  userService = inject(UserService);

  @HostBinding('class') class = 'h-full';
  public passwordForm: FormGroup = this.fb.group({});
  oldPass: FormControl = new FormControl('', [Validators.required]);
  newPass: FormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^((?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()~=_+}{":;'?{}/>.<,`\-|[\]]).{8,99})/),
  ]);
  confirmNewPass: FormControl = new FormControl('', [Validators.required]);
  showOldPassword = false;
  showPassword = false;
  processing = false;
  error = '';
  notHaveUppercase: (str: string) => boolean;
  notHaveDigit: (str: string) => boolean;
  notHaveSpecial: (str: string) => boolean;
  search: (str: string, regexStr: string) => void;

  constructor() {
    this.notHaveUppercase = notHaveUppercase;
    this.notHaveDigit = notHaveDigit;
    this.notHaveSpecial = notHaveSpecial;
    this.search = search;
  }

  ngOnInit(): void {
    this.passwordForm.addControl('current_password', this.oldPass);
    this.passwordForm.addControl('password', this.newPass);
    // this.passwordForm.addControl('confirmNewPass', this.confirmNewPass);
  }

  updatePassword() {
    this.processing = true;
    firstValueFrom(this.userService.updatePassword(this.passwordForm.value)).then(
      (res: any) => {
        if (res.success) {
        }
        this.processing = false;
      }, (err: any) => {
        console.log(err);
        if (err.error.message) {
          this.error = err.error.message;
        }
        else { this.error = JSON.stringify(err); }
        this.processing = false;
      }
    );
  }
}
