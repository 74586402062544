import { inject, Injectable, signal } from '@angular/core';
import { FileService } from '@data/file/file.service';
import { UserService } from '@data/user/user.service';
import { Capacity } from '@share-utils/data';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileController {
  currentUser = inject(UserService).currentUser;
  service = inject(FileService);
  capacity$ = signal(Capacity.empty());
  private static instance: FileController;

  constructor() {
    if (FileController.instance) {
      // Already created, return existing instance
      return FileController.instance;
    }
    FileController.instance = this;
  }

  // mana$ = toSignal(this.service.getMana(this.currentUser().uuid), { initialValue: new Mana(0, 0) });

  refresh() {
    // Update mana
    firstValueFrom(this.service.getCapacity(this.currentUser().uuid)).then(
      (capacity: Capacity) => {
        this.capacity$.set(capacity);
      }
    );
  }

  clear() {
    this.capacity$.set(Capacity.empty());
  }
}