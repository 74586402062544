import { inject, Injectable, signal } from '@angular/core';
import { ChatService } from '@data/chat/chat.service';
import { UserService } from '@data/user/user.service';
import { Mana } from '@share-utils/data';
import { countAnimation } from '@share-utils/view';
import { firstValueFrom, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ManaController {
  private static instance: ManaController;
  manaCountUpSubscription?: Subscription;
  userService = inject(UserService)

  constructor() {
    if (ManaController.instance) {
      // Already created, return existing instance
      return ManaController.instance;
    }
    ManaController.instance = this;
  }

  service = inject(ChatService);
  mana$ = signal(new Mana(0, 0));
  // mana$ = toSignal(this.service.getMana(this.currentUser().uuid), { initialValue: new Mana(0, 0) });

  refresh() {
    // Update mana
    if (this.userService.getUserId() == '') return;
    firstValueFrom(this.service.getMana(this.userService.getUserId())).then(
      (mana: Mana) => {
        this.manaCountUpSubscription = countAnimation(this.mana$().value, mana.value, 1000).subscribe(value => {
          this.mana$.set(new Mana(value, mana.max));
          if (value == mana.value) {
            this.manaCountUpSubscription?.unsubscribe();
          }
        })
      });
  }

  clear() {
    this.manaCountUpSubscription?.unsubscribe();
    this.mana$.set(new Mana(0, 0));
  }
}