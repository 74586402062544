import { HttpBackend } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { Database, DataSnapshot, get, ref, set, update } from '@angular/fire/database';
import { collection, collectionData, Firestore, getCountFromServer, QueryDocumentSnapshot, WithFieldValue } from '@angular/fire/firestore';
import { environment } from '@environments';
// import { QueryDocumentSnapshot, WithFieldValue, doc } from 'firebase/firestore';
// import { Firestore } from 'firebase/firestore';
import { firstValueFrom, map, } from 'rxjs';
import ChatUser from './chat-user';

export const CURRENT_ADMIN = 'flutter.currentUser';

const adminServerApi = environment.adminApi;
// const adminServerApi = 'http://127.0.0.1:5001/kyonsvn-stg/asia-east1/adminApi';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  afAuth = inject(Auth);
  db = inject(Database);
  fs = inject(Firestore);
  backend = inject(HttpBackend);

  async resetMana(id: string): Promise<boolean> {
    const userRef = ref(this.db, `users/${id}`);
    return get(userRef).then((snapshot: DataSnapshot) => {
      // return false;
      if (!snapshot.exists()) {
        return false;
      }
      const user = snapshot.toJSON() as { mana: number, defaultMana: number };
      user.mana = user.defaultMana;
      return set(userRef, user).then(() => {
        return true;
      }, (error) => {
        console.error(error);
        return false;
      });
    }, (error) => {
      console.error(error);
      return false;
    })
  }

  async getUsers(): Promise<Array<ChatUser>> {
    const usersStorage: any[] = JSON.parse(window.localStorage.getItem('users') ?? '[]');
    if (usersStorage.length > 0) {
      return usersStorage.map((user) => ChatUser.fromJson(user));
    }
    console.log('get users from firestore');

    const collectionRef = collection(this.fs, `users`).withConverter(userConverter);
    const users = await firstValueFrom(collectionData(collectionRef)
      .pipe(map((users) => {
        console.log(users);

        return users
          .filter(user => user.email).map(user => ChatUser.fromJson(user));
      })));
    window.localStorage.setItem('users', JSON.stringify(users));
    return users;
  }
  async countUsers() {
    const collectionRef = collection(this.fs, `users`);
    const snapshot = await getCountFromServer(collectionRef);

    return snapshot.data().count;
  }

  clearStorage() {
    window.localStorage.removeItem('users');
    window.localStorage.removeItem('chats');
  }

  async getConfig(): Promise<any> {
    const docRef = ref(this.db, 'configurations/settings');
    const doc = await get(docRef);
    return doc.toJSON();
  }

  async getDefaultMana(): Promise<number> {
    const config = await this.getConfig();
    if (config == undefined) return -1;
    console.log(config);

    return config['defaultMana'] ? Number(config['defaultMana']) : -1;
  }

  async getContext(): Promise<any> {
    const config = await this.getConfig();
    if (config == undefined) return {};
    return { en: config.en, vi: config.vi };
  }

  async getFileValidation(): Promise<any> {
    const data = {
      maxFileSize: 0,
      maxImageSize: 0,
      allowedFileTypes: [''],
      maxStorageSize: 0,
    }
    const config = await this.getConfig();
    if (config == undefined) return data;
    data.maxFileSize = config['maxFileSize'];
    data.maxImageSize = config['maxImageSize'];
    data.allowedFileTypes = Object.values(config['allowedFileTypes']);
    data.maxStorageSize = config['maxStorageSize'];
    return data;
  }

  saveContext(context: any) {
    const docRef = ref(this.db, 'configurations/settings');
    update(docRef, context);
  }

  saveDefaultMana(defaultMana: number) {
    const docRef = ref(this.db, 'configurations/settings');
    update(docRef, { defaultMana });
  }

  saveFileValidation(fileValidation: { maxFileSize: number; maxImageSize: number; allowedFileTypes: string[]; }) {
    const docRef = ref(this.db, 'configurations/settings');
    update(docRef, fileValidation);
  }
}

const userConverter = {
  toFirestore(value: WithFieldValue<any>) {
    return { value };
  },
  fromFirestore(snapshot: QueryDocumentSnapshot) {
    return { id: snapshot.id, ...snapshot.data() };
  },
};
