export const environment = {
  production: false,
  name: 'STUDENT',
  serverApi: 'https://student-api-dev.kyons.vn',
  origin: 'https://student-dev.kyons.vn',
  firebase: {
    apiKey: "AIzaSyArBEUjVlWNlMjUsdnpmCybsUOamQUXhOo",
    authDomain: "kyonsvn-dev.firebaseapp.com",
    databaseURL: "https://kyonsvn-dev-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "kyonsvn-dev",
    storageBucket: "kyonsvn-dev.appspot.com",
    messagingSenderId: "528206469210",
    appId: "1:528206469210:web:171078b693868bbc75a063",
  },
  chatApi: 'https://chatapi-ox2opk6u3q-de.a.run.app',
  fileApi: 'https://fileapi-ox2opk6u3q-de.a.run.app',
  sandboxApi: 'https://v1-ox2opk6u3q-de.a.run.app',
  adminApi: 'https://adminapi-ox2opk6u3q-de.a.run.app',
  workspaceApi: 'https://workspaceapi-ox2opk6u3q-de.a.run.app',
  vapidKey: 'BP-BjvXQUjaznK89An_nvZWRmP6PCQxIGQ9OexTGstwXGbTgdPy5jkFtr9SIBJpUXZOMzHnQ_1-PTq2_jVP4ylI',
  momo: 'https://test-payment.momo.vn',
  zalo: {
    APP_ID: "3958620017059112062",
    CALLBACK_URL: "http://localhost:4200/auth"
  }
};
