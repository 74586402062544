import { CommonModule, DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, HostBinding, inject, OnDestroy, OnInit, Renderer2, signal, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { FileController } from '@app/file.controller';
import { ChatService } from '@data/chat/chat.service';
import { FileService } from '@data/file/file.service';
import { NavigationService } from '@data/navigation/navigation.service';
import { UserService } from '@data/user/user.service';
import { FileSelectionComponent } from '@share-components';
import { Chat, FileData, FilePlaceholder } from '@share-utils/data';
import { Subscription } from 'rxjs';
// import { FileSelectionComponent } from '@view/share-components/file-selection/file-selection.component';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, FileSelectionComponent, MatIconModule],
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.scss'],
})
export class FileManagerComponent implements OnInit, OnDestroy {
  @HostBinding('class') class = 'app-full items-center relative chat';

  fileService = inject(FileService);
  userService = inject(UserService);
  document = inject(DOCUMENT);
  renderer = inject(Renderer2);
  chatService = inject(ChatService);
  paths = inject(NavigationService).paths;
  router = inject(Router);
  fileController = inject(FileController);

  capacity = this.fileController.capacity$;
  files: FileData[] | null = null;
  detail: FileData = FileData.empty();
  edittingDetail = false;
  updatingDetail = false;
  fileName = '';
  image: FilePlaceholder | null = null;
  file: File | null = null;
  showConfirmDelete = false;
  accetp = this.fileService.accept;
  detailChats: Chat[] | null = null;
  error = '';

  // searchFile = '';
  // dataSource: MatTableDataSource<FileData> = new MatTableDataSource<FileData>([]);
  // displayedColumns: string[] = ['name'
  //   , 'mimeType', 'size', 'createdAt'
  // ];
  // onSearchChat() { }

  isSmMenuHide = signal(true);
  capacitySubscription: Subscription | null = null;
  updateFileSubscription: Subscription | null = null;
  getChatSubscription: Subscription | null = null;
  selectionSubscription: Subscription | null = null;
  deleteFileSubscription: Subscription | null = null;
  currentUser = this.userService.currentUser;

  @ViewChild(MatSort) sort: MatSort = new MatSort();
  @ViewChild(MatPaginator) paginator: MatPaginator = new MatPaginator(
    new MatPaginatorIntl(),
    ChangeDetectorRef.prototype
  );
  @ViewChild(FileSelectionComponent) selection!: FileSelectionComponent;
  @ViewChild('inputFile') inputFileElm!: ElementRef<HTMLInputElement>;
  @ViewChild('errorDialog') errorDialogElm!: ElementRef<HTMLDialogElement>;

  ngOnInit(): void {
    this.updateCapacity();
  }

  updateCapacity() {
    this.fileController.refresh();
  }

  addFile() {
    this.file = null;
    this.inputFileElm.nativeElement.value = '';
    this.inputFileElm.nativeElement.click();
  }

  toggleMenu() {
    this.isSmMenuHide.set(!this.isSmMenuHide());
    this.isSmMenuHide() ? this.renderer.removeClass(this.document.body, 'overflow-hidden') : this.renderer.addClass(this.document.body, 'overflow-hidden');
  }



  openDetails(file: FileData) {
    this.detail = file;
    this.fileName = file.name;
    this.edittingDetail = false;
    this.updatingDetail = false;
    this.isSmMenuHide.set(false);
  }

  updateFileName() {
    this.updatingDetail = true;
    if (this.detail.isEmpty()) return;
    this.updateFileSubscription = this.fileService.updateFileName(this.currentUser().uuid, this.detail.id, this.fileName).subscribe({
      next: (success) => {
        if (!success) {
          alert('Có lỗi');
        }
        this.updatingDetail = false;
        this.edittingDetail = false;
        this.detail.name = this.fileName;
        this.selection.updateFile(this.detail);
      }
    })
  }

  hideDetail() {
    this.detail = FileData.empty();
  }

  onFileSelected($event: Event) {
    if ($event.target == null) return;
    const target = $event.target as HTMLInputElement;
    // convertFile(files[0]).subscribe(base64 => {
    //   this.image = base64;
    // });
    const reader = new FileReader();
    if (target.files && target.files[0]) {
      const file = target.files[0];
      if (file.type.split('/')[0] !== 'image' && file.size > 5 * 1024 * 1024) {
        alert("Không thể tải ảnh lớn hơn 5 MB");
        return;
      }
      if (file.size > 15 * 1024 * 1024) {
        alert("Không thể tải tập tin lớn hơn 15 MB");
        return;
      }
      this.file = file;
      this.image = new FilePlaceholder(this.file.name, this.file.type, this.file.size);
      reader.readAsDataURL(file);
      reader.onloadend = this.onloadend.bind(this, reader);
    }
  }

  onloadend(reader: FileReader) {
    if (this.image == null || this.file == null) return;
    const result = reader.result as string;
    const splits = result.split(',');
    this.image.base64 = reader.result as string;
    if (splits[0].includes('image/png')) {
      this.image.mimeType = 'image/png'
    }
    else if (splits[0].includes('image/jpg')) {
      this.image.mimeType = 'image/jpg'
    }
    this.selectionSubscription = this.selection.uploadFile(this.file, this.image, (fileId) => {
      this.updateCapacity();
    }, (code) => {
      if (code === 8) {
        this.error = 'Không đủ dung lượng bộ nhớ';
        this.errorDialogElm.nativeElement.showModal();
      }
      else if (code === 4) {
        this.error = 'Tập tin không đúng định dạng cho phép';
        this.errorDialogElm.nativeElement.showModal();
      }
      else if (code === 8) {
        this.error = 'Không đủ dung lượng bộ nhớ';
        this.errorDialogElm.nativeElement.showModal();
      }
    });
  }

  showConfirmDeleteDialog() {
    this.getChatSubscription = this.chatService.getChatsByIds(this.currentUser().uuid, this.detail.chatIds ?? []).subscribe({
      next: (chats) => {
        this.detailChats = chats;
        this.showConfirmDelete = true;
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  deleteFile() {
    this.deleteFileSubscription = this.selection.deleteFile(this.detail.id, () => {
      this.showConfirmDelete = false;
      this.detail = FileData.empty();
      this.isSmMenuHide.set(true);
      setTimeout(() => {
        this.updateCapacity();
      }, 500)

    });
  }

  openChat(chat: Chat) {
    window.open(this.paths.chat.path.replace(':id', chat.id), '_blank');
  }

  ngOnDestroy(): void {
    if (this.capacitySubscription) this.capacitySubscription.unsubscribe();
    if (this.updateFileSubscription) this.updateFileSubscription.unsubscribe();
    if (this.getChatSubscription) this.getChatSubscription.unsubscribe();
    if (this.selectionSubscription) this.selectionSubscription.unsubscribe();
    if (this.deleteFileSubscription) this.deleteFileSubscription.unsubscribe();
  }

}
